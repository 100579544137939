import {useParams} from "react-router";
import {useUserAllCategoryMutation} from "../../../store/user/actions";
import React, {useEffect, useState} from "react";
import TimepassInfoBanner from "../Banner/TimepassInfoBanner";
import {useNavigate} from "react-router-dom";

const CategoryComponent = () => {
    const {slug} = useParams();
    const [userAllCategory] = useUserAllCategoryMutation();
    const [games, setGames] = useState([]);
    const [name, setName] = useState("");
    const navigate = useNavigate();

    const [canFetch, setCanFetch] = useState(true);
    const fetchSingleCategory = async (page) => {
        try {
            const res = await userAllCategory({
                categoryId: slug,
                page: page + 1,
            }).unwrap();

            setGames((prevGames) => [...prevGames, ...res.results.games]);
            setName(res.results.name);

            if (res.next) {
                setTimeout(() => {
                    fetchSingleCategory(page + 1);
                }, 0);
            } else {
                setCanFetch(false);
            }
        } catch (error) {
            console.error(error);
            navigate(-1)
        }
    };

    useEffect(() => {
        if (canFetch) {
            fetchSingleCategory(0);
        }
    }, [slug]); // Re-fetch when slug changes

    return <>
        <div className={`${process.env.REACT_APP_THEME === "dark" ? "bg-black" : "bg-white"}`}>
            {process.env.REACT_APP_ENABLE_HEADER === "true" &&
                <header
                    className={`bg-black py-2 px-4 flex items-center justify-between shadow-md ${process.env.REACT_APP_THEME === "dark" ? "bg-black" : "bg-white"}`}>
                    <div className="flex items-center space-x-3 mx-auto">
                        <a href={"/"} className={"outline-none"}>
                            <img
                                src={`/${process.env.REACT_APP_BRAND_LOGO}.png`}
                                alt="Brand Logo"
                                className="h-12 w-auto object-contain rounded-xl"
                            />
                        </a>
                    </div>
                </header>}
            <h1 className={`${process.env.REACT_APP_THEME === "dark" ? "text-white" : "text-black"} text-center font-satoshi my-4`}>{name}</h1>
            <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-4 mx-4">
                {games.reduce((acc, _, i) => {
                    if (i % 3 === 0) {
                        acc.push(
                            <React.Fragment key={i}>
                                {/* First game - spans 2 columns */}
                                <div className="col-span-2">

                                    <a href={"/" + games[i]?.slug} className={"outline-none"}>
                                        <img
                                            draggable="false"
                                            src={games[i]?.property?.thumbnail}
                                            alt={games[i]?.name}
                                            className="w-full h-full object-cover rounded-lg"
                                        />
                                    </a>
                                </div>

                                {/* Next two games - stacked vertically in 1 column */}
                                <div className="col-span-1 flex flex-col gap-4">
                                    {i + 1 < games.length && (
                                        <a href={"/" + games[i + 1]?.slug}
                                           className={"outline-none w-full h-1/2 object-cover rounded-lg"}>
                                            <img
                                                draggable="false"
                                                src={games[i + 1]?.property?.thumbnail}
                                                alt={games[i + 1]?.name}
                                                className="w-full h-full object-cover rounded-lg"
                                            />
                                        </a>
                                    )}
                                    {i + 2 < games.length && (
                                        <a href={"/" + games[i + 2]?.slug}
                                           className={"outline-none w-full h-1/2 object-cover rounded-lg"}>
                                            <img
                                                draggable="false"
                                                src={games[i + 2]?.property?.thumbnail}
                                                alt={games[i + 2]?.name}
                                                className="w-full h-full object-cover rounded-lg"
                                            />
                                        </a>

                                    )}
                                </div>
                            </React.Fragment>
                        );
                    }
                    return acc;
                }, [])}
            </div>


            {process.env.REACT_APP_ENABLE_FOOTER === "true" &&
                <div className={"mt-[200px]"}>
                    <TimepassInfoBanner/>
                </div>
            }

        </div>


    </>
}

export default CategoryComponent